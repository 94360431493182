<template>
  <div>
    <back-navigation title="Edit Item" />
    <div
      v-if="loadingDetail !== 'error'"
    >
      <div
        v-if="loadingDetail"
        key="1"
        class="d-flex align-items-center justify-content-center"
        style="height: 80vh"
      >
        <b-spinner variant="primary" />
      </div>
      <div
        v-else
        key="2"
      >
        <b-row>
          <b-col
            cols="12"
            xl="8"
          >
            <items-form
              ref="itemsForm"
              v-model="itemData"
            />
          </b-col>
          <b-col
            cols="12"
            xl="4"
            class="mb-5"
          >
            <sticky-panel>
              <XyzTransition
                appear
                xyz="fade right-3 delay-2"
              >
                <b-card v-if="itemData.packaging && itemData.itemType">
                  <h4>Summary</h4>
                  <div
                    v-if="itemData.selectedColor"
                    class="d-flex align-items-center"
                  >
                    <div
                      v-if="itemData.selectedColor.hex_code"
                      class="selectedcolor mr-50"
                      :style="`background-color: #${itemData.selectedColor.hex_code};`"
                    />
                    <div>
                      <small class="text-muted">{{ itemData.selectedColor.id_ral }}</small>
                      <h5 class="m-0">
                        {{ itemData.selectedColor.eng_name }}
                      </h5>
                    </div>
                  </div>
                  <div v-if="sellingPrice.length">
                    <hr>
                    <h5>Daftar Harga</h5>
                    <div class="mt-1 d-flex justify-content-between mb-50">
                      <small
                        class="text-muted cursor-pointer"
                        @click="sortItems = {
                          by: 'cate',
                          sort: sortItems.by !== 'cate' ? 'asc' : sortItems.sort === 'asc' ? 'desc' : 'asc'
                        }"
                      >
                        Kategori
                        <span v-if="sortItems.by === 'cate'">
                          <feather-icon
                            v-if="sortItems.sort === 'asc'"
                            icon="ArrowUpIcon"
                          />
                          <feather-icon
                            v-else
                            icon="ArrowDownIcon"
                          />
                        </span>
                      </small>
                      <small
                        class="text-muted cursor-pointer"
                        @click="sortItems = {
                          by: 'price',
                          sort: sortItems.by !== 'price' ? 'asc' : sortItems.sort === 'asc' ? 'desc' : 'asc'
                        }"
                      >
                        Harga
                        <span v-if="sortItems.by === 'price'">
                          <feather-icon
                            v-if="sortItems.sort === 'asc'"
                            icon="ArrowUpIcon"
                          />
                          <feather-icon
                            v-else
                            icon="ArrowDownIcon"
                          />
                        </span>
                      </small>
                    </div>
                    <div
                      v-for="(item, index) in sellingPrice"
                      :key="index"
                      class="d-flex justify-content-between align-items-center mt-25"
                    >
                      <h6>
                        {{ item.priceCategory.name }}
                      </h6>
                      <h6>
                        {{ formatCurrency(item.price) }}
                      </h6>
                    </div>
                  </div>
                  <hr>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    variant="primary"
                    @click="loadingAdd ? null : submitForm()"
                  >
                    <span v-if="!loadingAdd">Submit</span>
                    <b-spinner
                      v-else
                      small
                    />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    variant="outline-secondary"
                    @click="resetForm()"
                  >
                    Reset
                  </b-button>
                </b-card>
              </XyzTransition>
            </sticky-panel>
          </b-col>
        </b-row>
      </div>
    </div>
    <error-endpoint
      v-else
      :key="error"
      ref="error"
      :error="error"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import BackNavigation from '@/components/misc/BackNavigation.vue'
import StickyPanel from '@/components/misc/StickyPanel.vue'
import { mapGetters } from 'vuex'
import ErrorEndpoint from '@/components/misc/ErrorEndpoint.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatCurrency } from '@core/utils/filter'
import ItemsForm from './ItemsForm.vue'

export default {
  components: {
    BCard,
    BackNavigation,
    BRow,
    BCol,
    StickyPanel,
    BButton,
    BSpinner,
    ItemsForm,
    ErrorEndpoint,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formatCurrency,
      loadingDetail: true,
      loadingAdd: false,
      error: '',
      itemData: {
        vs: 0,
        selectedColor: '',
        packaging: '',
        liter: 0,
        itemType: '',
        agent_item_id: '',
        ratio_agent: 0,
        agent_item_2_id: '',
        ratio_agent_2: 0,
        recommended_thinner: '',
        ratio_recommended_thinner: 5,
      },
      sortItems: { by: 'price', sort: 'asc' },
      detailSellingPrice: [],
      prevItems: '',
    }
  },
  computed: {
    ...mapGetters({
      itemTypes: 'getItemTypes',
      packagings: 'getPackagingsList',
      allItems: 'getItems',
    }),
    sellingPrice() {
      const price = this.detailSellingPrice

      if (this.sortItems.by === 'price') {
        return price.sort((a, b) => (this.sortItems.sort === 'asc' ? a.price - b.price : b.price - a.price))
      }

      return price.sort((a, b) => (this.sortItems.sort === 'asc' ? a.priceCategory.name.localeCompare(b.priceCategory.name, undefined, {
        numeric: true,
        sensitivity: 'base',
      }) : b.priceCategory.name.localeCompare(a.priceCategory.name, undefined, {
        numeric: true,
        sensitivity: 'base',
      })))
    },
  },
  async mounted() {
    await this.$store.dispatch('getPackagings')
    this.$store.dispatch('getItemsDetail', this.id).then(async result => {
      this.detailSellingPrice = result.detailSellingPrice
      this.itemData.vs = result.vs_volume_solid
      this.itemData.selectedColor = result.color.id_ral ? result.color : null
      this.itemData.packaging = {
        id: this.packagings.filter(el => el.name === result.packaging_name)[0].id,
        name: result.packaging_name,
      }
      this.itemData.liter = result.liter
      this.itemData.itemType = result.type
      this.itemData.ratio_agent = result.ratio_agent ? result.ratio_agent : 0
      this.itemData.ratio_agent_2 = result.ratio_agent_2 ? result.ratio_agent_2 : 0
      this.itemData.ratio_recommended_thinner = result.ratio_recommended_thinner_id ? result.ratio_recommended_thinner_id : 0
      this.itemData.data = result

      if (result.agent_item_id) {
        await this.$store.dispatch('getItemsDetail', result.agent_item_id).then(agent => {
          this.itemData.agent_item_id = agent
        })
      }

      if (result.agent_item_2_id) {
        await this.$store.dispatch('getItemsDetail', result.agent_item_2_id).then(agent => {
          this.itemData.agent_item_2_id = agent
        })
      }

      if (result.recommended_thinner_id) {
        await this.$store.dispatch('getItemsDetail', result.recommended_thinner_id).then(thinner => {
          this.itemData.recommended_thinner = thinner
        })
      }
      if (this.prevItems === '') this.prevItems = JSON.stringify(this.itemData)
      this.loadingDetail = false
    }).catch(error => {
      this.loadingDetail = 'error'
      this.error = `${error.graphQLErrors[0].message}`
    })
  },
  methods: {
    submitForm() {
      this.$refs.itemsForm.validate().then(success => {
        if (success) {
          this.loadingAdd = true
          this.$store.dispatch('updateItems', {
            item_id: this.id,
            vs_volume_solid: this.itemData.vs,
            color_id_ral: this.itemData.selectedColor?.id_ral ?? null,
            packaging: this.itemData.packaging.id,
            liter: this.itemData.liter,
            agent_item_id: this.itemData.agent_item_id ? this.itemData.agent_item_id.id : null,
            ratio_agent: this.itemData.agent_item_id ? parseFloat(this.itemData.ratio_agent) : null,
            agent_item_2_id: this.itemData.agent_item_2_id ? this.itemData.agent_item_2_id.id : null,
            ratio_agent_2: this.itemData.agent_item_2_id ? parseFloat(this.itemData.ratio_agent_2) : null,
            recommended_thinner_id: this.itemData.recommended_thinner ? this.itemData.recommended_thinner.id : null,
            ratio_recommended_thinner_id: this.itemData.recommended_thinner ? parseFloat(this.itemData.ratio_recommended_thinner) : null,
            item_type: this.itemData.itemType.id,
          }).then(result => {
            this.loadingAdd = false
            if (result.status.toLowerCase() === 'success') {
              this.$store.dispatch('getAllItems')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil mengupdate data item!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$router.push({ name: 'items' })
            }
          }).catch(err => {
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Harap periksa kembali data yang anda ',
                icon: 'EditIcon',
                variant: 'error',
              },
            })
            this.loadingAdd = false
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    resetForm() {
      this.itemData = JSON.parse(this.prevItems)
      // this.itemData = {
      //   vs: 0,
      //   selectedColor: '',
      //   packaging: '',
      //   liter: 0,
      //   itemType: '',
      //   agent_item_id: '',
      //   ratio_agent: 0,
      //   agent_item_2_id: '',
      //   ratio_agent_2: 0,
      //   recommended_thinner: '',
      //   ratio_recommended_thinner: 5,
      // }
      // this.$refs.itemsForm.reset()
    },
  },
}
</script>

<style scoped>
.selectedcolor {
  width: 36px;
  height: 36px;
  border-radius: 4px;
}

@media(max-height: 800px) {
  .fixed {
    position: static;
    width: 100%;
    transition: opacity 500ms linear;
  }
}
</style>
